<template>

<app-page>

	<app-page-head :title="title" back="Groups">

		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :loading="is.saving" :disabled="!isDirty" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action route="Group.Delete" v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" tooltip="Delete" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true" is-divided="true" :grow="true">

			<app-input-text v-model="model.title" :validation="$v.model.title" label="Title" placeholder="Enter title" :maxlength="64" />
			<app-input-suggest v-model="model.category" :validation="$v.model.category" store="categories" image="icon" color="background" :show-all="true" label="Category" placeholder="Library" />
			<app-input-slug v-model="model.slug" :validation="$v.model.slug" :source="model.title" label="Slug" placeholder="Enter slug" :maxlength="64" />
			<app-input-image v-model="model.image" :validation="$v.model.image" label="Thumbnail" />
			<app-input-toggle v-model="model.visible" label="Visible" />
			<app-input-toggle v-model="model.searchable" label="Searchable" />
			<app-input-tags v-model="model.terms" :dynamic-tag="model.title" label="Terms" />
			<app-input-texts v-model="model.texts" label="Content blocks" store="groups" route="Group.Text" />

		</app-page-content-section>

		<app-page-content-link v-if="!isNew" is-divided="true" icon="items" title="Items" route="Group.Items" :params="{id: model.id}" :count="itemCount" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: 0,
				slug: '',
				category: 0,
				title: '',
				image: '',
				visible: 1,
				searchable: 1,
				terms: [],
				texts: [],
				order: 0
			},
			param: 'group',
			noun: 'Group',
			store: 'groups',
			callbacks: [
				'textRemove',
				'textDelete',
				'textInsert',
				'textReplace'
			]
		}

	},

	validations: {
		model: {
			title: {
				required,
				maxLength: maxLength(64)
			},
			slug: {
				required
			},
			category: {
				required,
				minValue: minValue(0)
			},
			image: {
				required
			}
		}
	},

	computed: {

		texts: function() {

			return this.$store.getters['texts/find/index'](this.model.texts)

		},

		itemCount: function() {

			return this.$store.getters['items/find/group'](this.model.id).length

		}

	},

	methods: {

		default: function() {

			if (this.$route.params.parent) this.model.category = this.$route.params.parent

		},

		textRemove: function(id) {

			this.model.texts.splice(this.model.texts.indexOf(id), 1)

		},

		textInsert: function(e) {

			this.model.texts.splice(e.position, 0, e.id)

		},

		textReplace: function(e) {

			this.model.texts[this.model.texts.indexOf(e.from)] = e.to

		},

		textDelete: function(id) {

			this.model.texts.splice(this.model.texts.indexOf(id), 1)
			if (this.original.texts.indexOf(id) !== -1) this.original.texts.splice(this.original.texts.indexOf(id), 1)

		}

	}

}

</script>

<style scoped>

</style>
